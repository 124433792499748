<template>
	<footer class="bg-Gray ">
		<div class=" left-right-moon pb-12 pt-32 w-full ">
			<div class="container mx-auto px-5 md:px-8">
				<div
					class="flex items-center justify-center lg:justify-between flex-wrap lg:flex-nowrap text-white text-right tracking-wide leading-relaxed"
				>
					<!-- <img class="mx-auto lg:mx-0" height="80" width="80" src="../assets/Hero.svg" alt="" /> -->
					<img @click="$router.push('/')"  class="cursor-pointer mx-auto lg:mx-0" src="../assets/ZainOfficialLogoPNG.png" alt="زين" height="150" width="200" />
					<div
						class="w-full lg:w-auto mx-auto  text-right my-5"
					>
						<h3 class="text-infoGray">'العنوان ___</h3>
						<p class="my-2">النوفليين - طرابلس ، ليبيا</p>
					</div>
					<div
						class="w-full lg:w-auto mx-auto  text-right my-5"
					>
						<h3 class="text-infoGray">البريد الإلكتروني ___</h3>
						<a href="mailto:info@zeaan.com" class="my-2">info@zeaan.com</a>
					</div>
					
					<div
						class="w-full lg:w-auto mx-auto  text-right my-5" 
					>
						<h3 class="text-infoGray">رقم الهاتف ___</h3>
						<a class="my-2" href='tel:00218912507632'>218912507632</a>
					</div>
					<div class="w-full lg:w-auto mx-auto  text-right my-5">
						<h3 class="text-infoGray">وسائل التواصل الإجتماعي ___</h3>
						<ul class="flex justify-start items-center my-2">
							<li class="ml-2 text-2xl"><a target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:6790356857362931712?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6790356857362931712%29"> <i class="bx bxl-linkedin"></i></a></li>
							<!-- <li class="mx-2 text-2xl"><i class="bx bxl-facebook"></i></li>
							<li class="mx-2 text-2xl"><i class="bx bxl-twitter"></i></li> -->
						</ul>
					</div>
				</div>
				<span style="height: 1px" class="block  w-full rounded-md bg-infoGray my-8"></span>
				<div class="flex items-center justify-between">
					<button @click="scrollTop" class="outline-none focus:outline-none ">
						<img
							src="../assets/up-arrow.svg"
							alt="scroll top"
							class="transform transition-all duration-500 hover:-translate-y-2"
						/>
					</button>
					<p class="text-md font-medium tracking-wide leading-relaxed text-white">
						© {{ new Date().getFullYear() }} كل الحقوق محفوظة.
					</p>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
export default {
	methods: {
		scrollTop() {
			document.getElementById('TOP').scrollIntoView({ behavior: 'smooth' });
		},
	},
};
</script>
<style lang="scss">
.left-right-moon {
	background: url(../assets/left.png), url(../assets/right.png);
	background-attachment: fixed;
	background-size: 30%;
	background-position: left -300px bottom -180px, right -300px bottom -180px;
	background-repeat: no-repeat;
}
</style>
